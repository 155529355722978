import type { FC } from 'react';
import { useFragment, graphql } from 'react-relay/hooks';
import styled from '@emotion/styled';
import { Breakpoint } from '@pafcloud/style';
import { Link, imageRemapSize } from '@pafcloud/base-components';
import type { HeroLogo_contentBlock$key } from './__generated__/HeroLogo_contentBlock.graphql';

const contentBlockFragment = graphql`
  fragment HeroLogo_contentBlock on HeroLogoBlock {
    href
    ariaLabel
    logo {
      ...utils_imageProperties @relay(mask: false)
      altText
    }
    logoMobile {
      ...utils_imageProperties @relay(mask: false)
      altText
    }
  }
`;

const Logo = styled.picture({
  margin: 0,
  display: 'block',
  userSelect: 'none',
});

const HeroLogoImage = styled.img({
  objectFit: 'contain',
  objectPosition: 'var(--object-position, center center)',
  height: 'auto',
  maxWidth: '72vw',
  maxHeight: 'min(30vh, 275px)',
  pointerEvents: 'none',

  [Breakpoint.TabletOrLarger]: {
    maxWidth: 'min(70vw, 550px)',
  },

  [Breakpoint.HDScreenOrLarger]: {
    maxHeight: '330px',
    maxWidth: 'min(70vw, 650px)',
  },
});

const LogoLink = styled(Logo)().withComponent(Link);

type HeroLogoComponentProps = {
  contentBlock: HeroLogo_contentBlock$key;
  loading?: 'lazy' | 'eager';
  className?: string;
  onHeroClick?: () => void;
};

const replaceMedia = (breakpoint: keyof typeof Breakpoint) => {
  return Breakpoint[breakpoint].replace('@media ', '');
};

const HeroLogo: FC<HeroLogoComponentProps> = ({ contentBlock, loading, className, onHeroClick }) => {
  const { logo, logoMobile, ariaLabel, href } = useFragment(contentBlockFragment, contentBlock);
  const mobileLogo = logoMobile ?? logo;

  const mobileSrcSet = imageRemapSize({ sizes: [360, 412, 800], src: mobileLogo.url });
  const desktopSrcSet = imageRemapSize({ sizes: [360, 420, 560, 655, 800, 1200], src: logo.url });

  const image = (
    <Logo className={className} onClick={onHeroClick}>
      <source
        srcSet={mobileSrcSet}
        width={mobileLogo.width}
        height={mobileLogo.height}
        media={replaceMedia('Phone')}
        sizes="72vw"
      />
      <source
        srcSet={desktopSrcSet}
        width={logo.width}
        height={logo.height}
        media={replaceMedia('TabletOrLarger')}
        sizes={`
          ${replaceMedia('HDScreenOrLarger')} 650px,
          ${replaceMedia('LaptopOrLarger')} 550px,
          70vw
        `}
      />

      <HeroLogoImage alt={logo.altText ?? ''} loading={loading} />
    </Logo>
  );

  if (href) {
    return (
      <LogoLink href={href} aria-label={ariaLabel ?? ''} className={className}>
        {image}
      </LogoLink>
    );
  }

  return image;
};

export default HeroLogo;
