/**
 * @generated SignedSource<<4cc3dc5c4b823c55b97a0e33adeedb64>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type HeroLogo_contentBlock$data = {
  readonly ariaLabel: string | null;
  readonly href: string | null;
  readonly logo: {
    readonly altText: string | null;
    readonly height: number;
    readonly url: string;
    readonly width: number;
  };
  readonly logoMobile: {
    readonly altText: string | null;
    readonly height: number;
    readonly url: string;
    readonly width: number;
  } | null;
  readonly " $fragmentType": "HeroLogo_contentBlock";
};
export type HeroLogo_contentBlock$key = {
  readonly " $data"?: HeroLogo_contentBlock$data;
  readonly " $fragmentSpreads": FragmentRefs<"HeroLogo_contentBlock">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "url",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "height",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "width",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "altText",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "HeroLogo_contentBlock",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "href",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "ariaLabel",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Image",
      "kind": "LinkedField",
      "name": "logo",
      "plural": false,
      "selections": (v0/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Image",
      "kind": "LinkedField",
      "name": "logoMobile",
      "plural": false,
      "selections": (v0/*: any*/),
      "storageKey": null
    }
  ],
  "type": "HeroLogoBlock",
  "abstractKey": null
};
})();

(node as any).hash = "979e695f592942a4025c506422ce7059";

export default node;
